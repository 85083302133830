.register-container {
    background: #fff;
    height: 100vh;
    text-align: start;
    padding: 20px 100px;

    @media screen and (max-width: 992px) {
        .register-title {
            text-align: center;
        }
    }

    @media screen and (max-width: 500px) {
        padding: 20px 20px;
    }
}

.input-form {
    margin-bottom: 15px;
}

.login-register {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;

    .login-register-link {
        margin-left: 1rem;
        color: #7288fa;
        font-weight: 700;
    }
}

.register-btn {
    width: 100%;
    min-height: 60px;
    box-shadow: 0px 8px 30px rgba(65, 89, 214, 0.3);
    border-radius: 41px;
    background: #7288fa;
    color: #fff;
    cursor: pointer;
    margin-top: 45px;
}

.register-failed {
    color: var(--error-color);
}
