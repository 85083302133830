.wrapper {
    width: 100%;
}

.inner {
    padding: 0 20px 20px 20px;
    background-color: var(--primary);
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.description {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: var(--white);
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.5;
}

.sub-title {
    color: var(--white);
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 2.4;
}

@media screen and (max-width: 1200px) {
    .title {
        font-size: 1.5rem;
    }

    .sub-title {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 992px) {
    .inner {
        flex-direction: column;
    }

    .title {
        display: none;
    }

    .sub-title {
        font-size: 1.5rem;
    }
}
