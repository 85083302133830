.form-group {
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(66, 71, 97, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 6px;

    .form-label {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .form-input {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2rem;
        caret-color: var(--primary);
        width: 90%;

        &::placeholder {
            color: var(--grey);
            font-weight: 200;
        }
    }

    .form-message-error {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 2rem;
        color: var(--error-color);
    }
}
.eye {
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
}

.invalid {
    border: 0.5px solid var(--error-color);
}
