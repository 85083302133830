.wrapper {
    padding: 26px 26px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.6rem;
    }

    .close {
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 2px;
        background-color: var(--grey);
        border-radius: 50%;
        color: var(--white);
        cursor: pointer;
    }
}

.description {
    max-width: 100%;
    text-align: center;
    word-wrap: normal;
}

.name {
    text-decoration: underline;
    color: var(--primary);
}

.name-remove {
    text-decoration: underline;
    color: var(--error-color);
}

.selection {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.error {
    color: var(--error-color);
}

.remove-btn {
    background-color: var(--error-color);
    border: none;

    &:hover {
        background-color: rgba(254, 44, 85, 0.6);
        ;
    }
}