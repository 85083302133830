.inner {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
}

.folder-item {
    cursor: pointer;
    background-color: #fff;
    width: 100%;
    padding: 20px;
    border-radius: 10px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .icon {
        position: absolute;
        top: -10px;
        left: 0;
    }

    .amount {
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        text-align: end;
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
    }

    .line {
        width: 80%;
        height: 0.3px;
        background-color: #a3b2c7;
        margin: 15px auto;
    }

    .update {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin-bottom: 10px;
    }

    .update-time {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
}
