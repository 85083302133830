.wrapper {
    padding: 26px 26px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.6rem;
    }

    .close {
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 2px;
        background-color: var(--grey);
        border-radius: 50%;
        color: var(--white);
        cursor: pointer;
    }
}


.input {
    min-width: 263px;
    width: 100%;
    padding: 16px 16px;
    background-color: var(--white);
    box-shadow: 0px 30px 40px rgba(89, 104, 179, 0.06), 0px 0px 30px rgba(89, 104, 178, 0.06);
    border-radius: var(--border-radius);
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
}

.save-btn {
    min-width: 263px;
    width: 100%;
    padding: 18px auto;
}

.disabled {
    opacity: 0.6;
}

.error {
    color: var(--error-color);
}