.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.menu {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.menu-item {
    padding: 10px 0;
    border-radius: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 7px 30px;

    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text-color);
    gap: 10px;

    .icon {
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
    }

    transition: background-color ease-in-out 200ms;

    &.active {
        color: #fff;
        background-color: var(--primary);
        opacity: 0.8;
        box-shadow: 0px 8px 30px rgba(65, 89, 214, 0.3);
    }

    &:hover {
        background-color: rgb(114, 136, 250, 0.3);
        color: var(--text-color);
    }

    @media screen and (max-width: 1200px) {
        padding: 7px 10px;
        p {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 992px) {
        padding: 7px 20px;
        .icon {
            display: none;
        }
    }
}

.footer {
    flex: 0.2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    // padding: auto 42px 10px;
    .background {
        flex: 0.9;
        width: 100%;
        background-color: var(--primary);
        opacity: 0.1;
        border-radius: var(--border-radius);
    }

    .image {
        position: absolute;
        bottom: 80px;
    }
}
