.wrapper {
    height: 100vh;
    text-align: start;
    padding: 50px 100px;

    .header {
        margin-bottom: 20px;

        .header-title {
            font-weight: 700;
            font-size: 40px;
        }

        .header-sub-title {
            font-size: 14px;
            font-weight: 200;
            line-height: 20px;
            color: var(--grey);
            margin-bottom: 50px;
        }

        @media screen and (max-width: 992px) {
            .header-title {
                font-weight: 600;
                font-size: 30px;
                line-height: 40px;
                margin-bottom: 20px;
            }
        }
    }

    @media screen and (max-width: 500px) {
        padding: 20px 20px;
    }
}

.form-wrapper {
    flex-grow: 1;
    padding: 40px 0;

    .submit-btn {
        width: 100%;
        box-shadow: 0px 8px 30px rgba(65, 89, 214, 0.3);
        border-radius: 41px;
        background: #7288fa;
        padding: 16px;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        margin-top: 25px;
    }
}

.error {
    color: var(--error-color);
}

.footer {
    border-top: 0.5px solid var(--grey);
    padding: 30px 0;

    .login-register {
        margin: 0rem 1rem 0 1rem;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;

        .login-register-link {
            margin-left: 1rem;
            color: #7288fa;
        }
    }

    .login-register-link {
        text-decoration: none;
        cursor: pointer;
        color: rgb(105, 105, 105);
        font-weight: 700;
    }

    .login-register-link:hover {
        text-decoration: underline;
        color: rgb(54, 51, 241);
    }
}
