.register-container {
    background: #fff;
    height: 100vh;
    text-align: start;
    padding: 20px 100px;
    display: flex;
    flex-direction: column;

    .register-title {
        font-size: 40px;
        font-weight: 700;
        color: #333f4e;
    }

    @media screen and (max-width: 600px) {
        .register-title {
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
            margin-bottom: 20px;
        }
    }

    .line_hr {
        margin-top: 50px;
        position: relative;
    }

    .text_line {
        position: absolute;
        top: -20px;
        right: 44%;
        background: #fff;
        padding: 0px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #333f4e;
    }

    @media screen and (max-width: 500px) {
        padding: 20px 20px;
    }
}

.text-center {
    text-align: center;
}

// .register-form {
//     display: flex;
//     font-size: 0.85rem;
//     font-weight: 600;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
// }

.register-input {
    border: none;
    padding: 0.35rem;
    font-family: 'Inter', sans-serif;
    margin: 0.75rem 0;
    border-radius: 4px;
    width: 100%;
}

.submit-btn {
    cursor: pointer;
    border: none;
    align-self: center;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.65rem;
    border-radius: 4px;
    color: white;
    background-color: rgb(161, 161, 161);
}

.register-error {
    margin-top: 1rem;
    align-self: center;
    color: rgb(255, 0, 55);
}

// ===================khoii====

.register-form {
    margin-top: 40px;

    .group {
        position: relative;
        margin-bottom: 30px;
    }

    input {
        font-size: 18px;
        padding: 15px;
        display: block;
        width: 100% !important;
        border: none;
        box-shadow: 0px 10px 30px rgba(66, 71, 97, 0.1);
        border-radius: 12px;
    }

    input:focus {
        outline: none;
    }

    label {
        color: #999;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 20px;
        top: 3px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    input:focus ~ label,
    input:valid ~ label {
        top: -20px;
        font-size: 14px;
        color: #5264ae;
    }
}

.submit__btn {
    width: 100%;
    box-shadow: 0px 8px 30px rgba(65, 89, 214, 0.3);
    border-radius: 41px;
    background: #7288fa;
    padding: 16px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin-top: 25px;
}

.login-register {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;

    .login-register-link {
        margin-left: 1rem;
        color: #7288fa;
        font-weight: 700;
    }
}

.sub-title {
    font-size: 1.4rem;
    color: var(--grey);
}
