.search,
.search-result {
    width: 482px;

    --search-height: 52px;
    --search-border-radius: 30px;
    --search-top-spacer: 9px;
    --search-button-width: 66px;
}

.search-result {
    // width: 100%;
    max-height: min((100vh - 96px) - 60px, 448px);
    min-height: 100px;
    padding: 8px 0;
    border-radius: 8px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 12px;
    display: flex;
    flex-direction: column;

    perspective-origin: right top;
    overflow-x: hidden;
    overflow-y: overlay;
    overscroll-behavior-y: contain;
    transform-style: preserve-3d;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(130, 129, 129, 0.15);
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
    }

    &:hover {
        &::-webkit-scrollbar {
            display: block;
            border-radius: 0;
            width: 8px;
        }
    }
}

.search {
    position: relative;
    height: var(--search-height);
    padding-left: 20px;
    display: flex;
    border-radius: var(--search-border-radius);
    border: 1.5px solid transparent;
    box-shadow: 0px 30px 40px rgba(89, 104, 179, 0.06), 0px 0px 30px rgba(89, 104, 178, 0.06);

    input {
        flex: 1;
        height: 100%;
        padding-right: 20px;
        color: var(--text-c);
        font-size: 1.6rem;
        caret-color: var(--primary);
        background-color: transparent;
        font-family: var(--font-family);
    }

    input:not(:placeholder-shown)~.search-btn {
        color: rgba(22, 24, 35, 0.75);
    }

    &::after {
        content: '';
        position: absolute;
        top: var(--search-top-spacer);
        right: var(--search-button-width);
        width: 1px;
        height: calc(var(--search-height) - var(--search-top-spacer) * 2);
        background-color: rgba(22, 24, 35, 0.08);
    }

    &:focus-within {
        border-color: rgba(22, 24, 35, 0.2);
    }
}

.search-title {
    padding: 5px 12px;
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(22, 24, 35, 0.5);
}

.clear,
.loading {
    position: absolute;
    right: calc(var(--search-button-width) + 16px);
    top: 50%;
    transform: translateY(-50%);
    color: rgba(22, 24, 35, 0.34);
}

.loading {
    animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
    from {
        transform: translateY(-50%) rotate(0);
    }

    to {
        transform: translateY(-50%) rotate(360deg);
    }
}

.search-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--search-button-width);
    height: 100%;
    border-top-right-radius: var(--search-border-radius);
    border-bottom-right-radius: var(--search-border-radius);
    font-size: 1.8rem;
    color: rgba(22, 24, 35, 0.34);
    background-color: var(--white);

    &:hover {
        cursor: pointer;
        background-color: rgba(22, 24, 35, 0.03);
    }

    &:active {
        background-color: rgba(22, 24, 35, 0.06);
    }
}