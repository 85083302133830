.otp-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    margin-bottom: 40px;
}

.otp-input {
    padding: 0 32px;
    height: 80px;
    width: 80px;
    border: 0.5px solid #a3b2c7;
    border-radius: var(--border-radius);
    font-size: 2.5rem;
}

.otp-input:focus {
    outline: none;
    border: 2px solid #000;
}
