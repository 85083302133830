.switch {
    display: flex;
    align-items: center;
    width: 46px;
    height: 24px;
    padding: 2px;
    background-color: var(--grey);
    border-radius: 22px;
    position: relative;
    cursor: pointer;
}

.turn-on {
    position: relative;
    background-color: #06a96d;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

    .switch-inner {
        position: relative;
        left: calc(100% - 22px);
    }
}

.switch-inner {
    position: relative;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    left: 1px;
    box-shadow: rgb(0 0 0 / 15%) 0px .5px 2px;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}