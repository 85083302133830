.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menu-main {
    flex: 0.85;
}

.menu-header {
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    gap: 10px;

    .back-btn {
        padding: 12px;
        border-radius: 50%;
        box-shadow: 0px 9.58333px 28.75px rgba(66, 71, 97, 0.1);
        cursor: pointer;
    }

    .header-title {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 24px;
    }
}

.menu-body {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .menu-item {
        width: 100%;
        border-radius: 30px;

        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 600;
        color: var(--text-color);

        transition: background-color ease-in-out 200ms;

        .icon {
            margin: 10px 14px 0 10px;
        }

        &.active {
            background-color: rgb(114, 136, 250, 0.3);
        }
    }

    .switch {
        left: 55px;
    }
}

.menu-footer {
    flex: 0.15;
    display: flex;
    align-items: center;
    justify-content: center;

    .logout-btn {
        display: flex;
        align-items: center;
        gap: 13px;
        padding: 14px 49px;
        background-color: var(--white);
        box-shadow: 0px 10px 30px rgba(66, 71, 97, 0.1);
        border-radius: var(--border-radius);
        color: #FF7474;
        cursor: pointer;
    }

    .logout-title {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 20px;
    }
}