.wrapper {
    border: 2px solid #F2F5F9;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
}

.small {
    height: 44px;
    width: 44px;
}

.medium {
    height: 50px;
    width: 50px;
}

.large {
    height: 82px;
    width: 82px;
}