.forgotPassword {
    background: #fff;
    height: 100vh;
    text-align: start;
    padding: 20px 100px;

    .forgotPassword-title {
        font-size: 46px;
        font-weight: 700;
        color: #333f4e;
    }

    @media screen and (max-width: 992px) {
        .forgotPassword-title {
            font-weight: 600;
            font-size: 30px;
            line-height: 40px;
        }
    }

    @media screen and (max-width: 600px) {
        .forgotPassword-title {
            font-weight: 600;
            font-size: 25px;
            line-height: 30px;
        }
    }

    .text_instruct {
        font-size: 14px;
        font-weight: 400;
        color: #333f4e;
        margin-bottom: 50px;
    }

    .submit__btn {
        width: 100%;
        box-shadow: 0px 8px 30px rgba(65, 89, 214, 0.3);
        border-radius: 41px;
        background: #7288fa;
        padding: 23px;
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        margin-top: 25px;
    }

    .disabled {
        opacity: 0.5;
    }

    .error {
        color: var(--error-color);
    }

    .success {
        color: #3dd9b3;
    }

    .line_hr {
        margin-top: 50px;
        position: relative;
    }

    .text_line {
        position: absolute;
        top: -20px;
        right: 44%;
        background: #fff;
        padding: 0px 20px;
        font-size: 16px;
        font-weight: 400;
        color: #333f4e;
    }

    .text-center {
        text-align: center;
    }

    @media screen and (max-width: 500px) {
        padding: 20px 20px;
    }
}

.forgotPassword-form {
    .group {
        position: relative;
        margin-bottom: 30px;
    }

    input {
        font-size: 18px;
        padding: 15px;
        display: block;
        width: 100%;
        border: none;
        box-shadow: 0px 10px 30px rgba(66, 71, 97, 0.1);
        border-radius: 12px;
    }

    input:focus {
        outline: none;
    }

    label {
        color: #999;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 20px;
        top: 3px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    input:focus ~ label,
    input:valid ~ label {
        top: -20px;
        font-size: 14px;
        color: #5264ae;
    }
}

@media screen and (max-width: 1024px) {
    .forgotPassword {
        height: 100%;
    }
}

.login-register {
    margin: 0rem 1rem 0 1rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;

    .login-register-link {
        margin-left: 1rem;
        color: #7288fa;
    }
}

.login-register-link {
    text-decoration: none;
    cursor: pointer;
    color: rgb(105, 105, 105);
    font-weight: 700;
}

.login-register-link:hover {
    text-decoration: underline;
    color: rgb(54, 51, 241);
}
