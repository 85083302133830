.result-item {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
        background: rgba(22, 24, 35, 0.03);
    }
}

.file-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    background-color: rgba(114, 136, 250, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-info {
    flex: 1;
    margin-left: 12px;
}

.file-name {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 20px;
}

.uploaded-at {
    font-size: 1.2rem;
    font-weight: 200;
    line-height: 20px;
    color: var(--grey);
}