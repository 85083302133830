.graphic-container {
    background-color: var(--primary);
    height: 100%;

    .graphic_padding_page {
        padding: 60px 50px;
    }

    .text-center {
        text-align: center;
    }

    .content_text_graphic {
        padding: 0px 55px;
        margin-bottom: 60px;

        h1 {
            font-weight: 700;
            font-size: 46px;
            color: #fff;
            margin-bottom: 18px;
            line-height: 1.5;
        }

        .sub-title {
            font-size: 16px;
            font-weight: 400;
            color: #fff;
        }
    }

    @media screen and (max-width: 1400px) {
        .content_text_graphic {
            .title {
                font-size: 40px;
                color: #fff;
                margin-bottom: 18px;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .image {
            display: none;
        }

        .content_text_graphic {
            padding: 20px 55px;

            .title {
                font-size: 36px;
                color: #fff;
                margin-bottom: 18px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .content_text_graphic {
            display: none;
        }
    }
}

.image {
    width: 50%;
    height: 50%;
    object-fit: cover;
}
