.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: flex;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.body {
    margin: auto;
    position: relative;
    background-color: #fff;
    border-radius: var(--border-radius);

    animation: fadeIn linear 0.2s;

    @keyframes fadeIn {
        from {
            transform: scale(0);
            opacity: 0;
        }

        to {
            transform: scale(100%);
            opacity: 100%;
        }
    }
}