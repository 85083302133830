.info-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-groups {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 26px;
}

.info-container {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(66, 71, 97, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    gap: 6px;
    min-height: 78px;

    .info-title {
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 2rem;
    }

    .info-data {
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2rem;
    }
}

.edit-btn {
    width: 136px;
    height: 52px;
}