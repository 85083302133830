.wrapper {
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.folder-title {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 100%;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 30px;
}

.size-total {
    font-size: 1.6rem;
    line-height: 24px;
}

.btn {
    display: flex;
    align-items: center;

    .sort-btn,
    .list-btn {
        margin-right: 20px;
    }

    .list-btn,
    .grid-btn {
        width: 40px;
        height: 40px;
        border-radius: 13.75px;
        cursor: pointer;
    }

    .active {
        background-color: var(--primary);
        color: var(--white);
    }
}

.container {
    margin: 20px 30px 0;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    height: 64px;

    perspective-origin: right top;
    overflow-x: hidden;
    overflow-y: overlay;
    overscroll-behavior-y: contain;
    transform-style: preserve-3d;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(130, 129, 129, 0.15);
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
    }

    &:hover {
        &::-webkit-scrollbar {
            display: block;
            border-radius: 0;
            width: 8px;
        }
    }
}

.container-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 25px;
}

.container-gird {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px;
}