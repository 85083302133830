//List file
.listStyle {
    background-color: var(--white);
    border-radius: 12px;
    padding: 14.5px;
    display: flex;
    justify-content: space-between;

    .file-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .file-icon {
            background-color: rgba(114, 136, 250, 0.08);
            border-radius: 50%;
            height: 60px;
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
        }

        .file-desc {
            display: flex;
            flex-direction: column;

            .file-title {
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 2.6rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 150px;
            }

            .uploaded-time {
                color: var(--grey);
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }
    }

    .sub-content {
        display: flex;
        align-items: center;

        .file-option {
            color: var(--grey);
            font-size: 2.2rem;
        }

        .file-size {
            display: none;

        }
    }
}

// Grid file
.gridStyle {
    background-color: var(--white);
    border-radius: 18px;
    padding: 17.2px 14px 17.2px 18px;
    display: flex;

    .file-content {
        display: flex;
        flex-direction: column;

        .file-icon {
            background-color: rgba(114, 136, 250, 0.08);
            border-radius: 50%;
            height: 82px;
            width: 82px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 22px;
        }

        .file-desc {
            display: flex;
            flex-direction: column;

            .file-title {
                font-weight: 600;
                font-size: 1.4rem;
                line-height: 22px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 150px;
            }

            .uploaded-time {
                color: var(--grey);
                font-weight: 200;
                font-size: 1.4rem;
            }
        }
    }

    .sub-content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-direction: column;

        .file-option {
            color: var(--grey);
            font-size: 2.2rem;
        }

        .file-size {
            color: var(--grey);
            font-weight: 200;
            font-size: 1.4rem;
            line-height: 1.4rem;
        }
    }
}

/////////////

.file-title {
    &:hover {
        cursor: pointer;
        text-decoration: underline;

    }
}

.file-option {
    &:hover {
        cursor: pointer;
    }
}