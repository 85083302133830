.wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close {
    position: absolute;
    right: 26px;
    top: 16px;
    background-color: var(--grey);
    border-radius: 50%;
    color: var(--white);
    cursor: pointer;
}

.title {
    font-weight: 600;
    font-size: 2rem;
    line-height: 26px;
    padding-bottom: 40px;
}

.confirm-btn {
    width: 100%;
    padding: 18px 0;
}