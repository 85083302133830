.wrapper {
    max-width: 293px;
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
        margin: 0 auto;
        justify-content: center;
    }
}

.logo-title {
    padding: 0 8px;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.6;
    color: var(--primary);
}

.textColor {
    color: #fff;
    font-weight: 500;
    font-size: 37.4161px;
    line-height: 56px;
}