.wrapper {
    display: flex;
    padding: 30px;
    gap: 30px;

    .col-right {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }

    .col-left {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
}
