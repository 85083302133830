.wrapper {
    background-color: var(--grey);
    width: 100%;
}

.inner {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--border-radius);
}

.container {
    display: flex;
    flex: 1;
    padding: 0 36px 36px 0;
}

.content {
    flex: 0.83;
    border-radius: var(--border-radius);
    background-color: #f2f4f8;
}

.sidebar {
    flex: 0.17;
    padding: 16px 26px 0 26px;
}
