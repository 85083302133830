.login-container {
    background: #fff;
    height: 100vh;
    text-align: start;
    padding: 20px 100px;

    .login-title {
        font-size: 4.6rem;
        font-weight: 700;
        color: var(--text-color);

        @media screen and (max-width: 992px) {
            text-align: center;
        }
    }

    .input-form {
        margin-bottom: 15px;
    }

    .sub-actions {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .remenber-me {
            display: flex;
            align-items: center;
            gap: 12px;

            .remenber-me-input {
                width: 24px;
                height: 24px;
                border: 2px solid #A3B2C7;
                border-radius: 16px;
            }

            .remenber-me-label {
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2rem;
            }
        }

        .forgot-password {
            color: var(--primary);
        }
    }

    .login-btn {
        width: 100%;
        min-height: 60px;
        box-shadow: 0px 8px 30px rgba(65, 89, 214, 0.3);
        border-radius: 41px;
        background: #7288fa;
        color: #fff;
        cursor: pointer;
        margin-top: 45px;
    }

    .login-failed {
        color: var(--error-color);
    }

    @media screen and (max-width: 500px) {
        padding: 20px 20px;
    }

}

.login-register {
    margin: 0rem 1rem 0 1rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;

    .login-register-link {
        margin-left: 1rem;
        color: #7288fa;
    }
}

.login-register-link {
    text-decoration: none;
    cursor: pointer;
    color: rgb(105, 105, 105);
    font-weight: 700;
}

.login-register-link:hover {
    text-decoration: underline;
    color: rgb(54, 51, 241);
}