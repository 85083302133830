.wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.upload-btn {
    margin-right: 40px;
    width: 136px;
}

.user {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    max-width: 124px;
    width: 100%;
}

.user-avatar {
    margin-right: 14px;
}

.user-name {
    flex: 1;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        text-decoration: underline;
    }
}