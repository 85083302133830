.wrapper {
    height: 100%;
    padding: 34px 37px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.header-title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 5.6rem;
}

.form-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .form-groups {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 26px;
    }

    .btn-container {
        display: flex;
        gap: 20px;

        .submit-btn {
            width: 136px;
            height: 52px;
        }
    }
}