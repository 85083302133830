.wrapper {
    padding: 26px 26px 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.6rem;
    }

    .close {
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 2px;
        background-color: var(--grey);
        border-radius: 50%;
        color: var(--white);
        cursor: pointer;
    }
}

.upload-dragger {
    width: 100%;
    height: 100%;
    padding: 36px;
    background-color: var(--white);
}

.file-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 36px;
    min-width: 348px;
    min-height: 72px;
    height: 100%;
    width: 100%;
}

.file-name {
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
}

.choose-btn {
    background-color: #fafafa;
}

.btn-actions {
    display: flex;
    // flex-direction: column;
    justify-content: center;
}

.save-btn {
    // min-width: 263px;
    // width: 100%;
    padding: 0 54px;
    margin-left: 16px;
}

.disabled {
    opacity: 0.6;
}

.error {
    color: var(--error-color);
}