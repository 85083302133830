.wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }

    .graphic-container {
        flex: 1;
    }

    .connect-container {
        flex: 1;
    }
}

.graphic-container {
    flex: 0.3;
}

.connect-container {
    flex: 0.7;
}
