.wrapper {
    flex-grow: 1;
    width: 100%;
}

.inner {
    height: 100%;
    background-color: var(--white);
    border-radius: var(--border-radius);
    border: 1px dashed var(--primary);
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 700;
    font-size: 2rem;
    color: var(--primary);
}

.description {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;
}

.info {
    border-bottom: 0.5px solid var(--grey);
    display: flex;
    align-items: center;

    .info-title {
        flex: 0.3;
        color: var(--grey);
        font-size: 1.4rem;
        font-weight: 400;
    }

    .info-data {
        flex: 0.7;
        font-weight: 700;
        color: var(--text-color);
    }
}

.update-link {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--primary);

    &:hover {
        text-decoration: underline;
    }
}