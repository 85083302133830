.wrapper {
    width: 100%;
    max-height: min((100vh - 96px) - 60px, 448px);
    min-height: 100px;
    border-radius: 20px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 12%) 0px 2px 12px;
    display: flex;
    flex-direction: column;
}

.menu-popper {
    padding: 14px 0;
    width: 254px;
}

.menu-body {

    .menu-item {

        margin-left: 0;
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: flex-start;
        border-radius: 0;
        line-height: 1.8rem;
        position: relative;

        &:hover {
            background-color: rgba(22, 24, 35, 0.03);
        }

        .item-title {
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 20px;
        }
    }
}