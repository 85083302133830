.wrapper {
    height: 100%;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header {

    .header-title {
        font-size: 3.2rem;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 20px;
    }

    .header-desc {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 3rem;
    }


}

.pin-container {
    flex-grow: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .pin-inner {
        background-color: var(--white);
        border-radius: var(--border-radius);
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .confirm-btn {
            width: 100%;
        }
    }


}

.footer {

    .sub-desc {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.6rem;
    }
}