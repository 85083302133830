.wrapper {
    width: 100%;
    height: 100%;
    max-height: 512px;
}

.inner {
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    background-color: #fff;
    padding: 20px 51px;
    display: flex;
    flex-direction: column;
}

.header {
    height: 45px;
    // margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-weight: 700;
        font-size: 2.4rem;
        // line-height: 3.6;
        color: var(--primary);
    }
}

.container {
    // width: 100%;
    // flex: 1;

    // perspective-origin: right top;
    overflow-x: hidden;
    // overflow-y: auto;
    // overscroll-behavior-y: contain;
    // transform-style: preserve-3d;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(130, 129, 129, 0.15);
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: rgba(0, 0, 0, 0);
    }

    &:hover {
        &::-webkit-scrollbar {
            display: block;
            border-radius: 0;
            width: 8px;
        }
    }

    .file-item {
        border-bottom: 0.1px solid rgba(22, 24, 35, 0.2);
    }

}