.wrapper {
    height: 96px;
}

.dark-wrapper {
    background-color: var(--black);
}

.inner {
    height: 100%;
    padding: 0 36px;
    display: flex;
    align-items: center;
}