.wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    min-width: 36px;
    padding: 9px 16px;
    border-radius: 41px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2;
    font-family: var(--font-family);

    cursor: pointer;
    background-color: var(--white);
    border: .5px solid transparent;
    user-select: none;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.icon+.title,
.title+.icon {
    margin-left: 16px;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    text-align: center;
}

//switch
.switch {
    left: 80px;
}

//separate
.separate {
    position: absolute;
    top: 0;
    left: 20px;
    width: 211px;
    height: 0.05px;
    background-color: rgba(163, 178, 199, 0.5);
}

// Button types
.rounded {
    border-radius: 999px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
    border-color: rgba(22, 24, 35, 0.12);

    &:hover {
        border-color: rgba(22, 24, 35, 0.2);
        background-color: rgba(22, 24, 35, 0.03);
    }
}

.primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);

    &:hover {
        border-color: var(--primary);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), var(--primary);
    }
}

.outline {
    color: var(--primary);
    border-color: currentColor;
    border: none;

    &:hover {
        border-color: currentColor;
        // background-color: #fe2c550f;
    }

    &.dark {
        color: var(--primary);
        border: 1px solid var(--primary);

        &:hover {
            border: .5px solid var(--primary);
        }
    }
}

.dark {
    background-color: #252525;
    color: #fff;
}

.borderline {
    min-width: 110px;
    height: 39.5px;
    padding: 0 16px;
    border: .5px solid rgba(22, 24, 35, 0.12);
    border-radius: 4px;

    .icon+.title,
    .title+.icon {
        margin-left: 0;
    }

    &:hover {
        background-color: rgba(22, 24, 35, 0.03);
    }

    &.dark {
        color: var(--text);
        border: none;

        &:hover {
            border: .5px solid #fff;
        }
    }
}

.iconOnly {
    // max-width: 36px;
    // height: 36px;
    // padding: 0;
    border: .5px solid rgba(22, 24, 35, 0.12);
    border-radius: 6px;

    .icon+.title,
    .title+.icon {
        margin-left: 0;
    }

    &:hover {
        background-color: rgba(22, 24, 35, 0.03);
    }

    &.dark {
        color: var(--text);
        border-color: #fff;
    }
}

.text {
    &:hover {
        text-decoration: underline;
    }
}

// Button sizes
.smallest {
    max-width: 36px;
    height: 36px;
    padding: 0;
    margin-top: .5px;
}

.small {
    min-width: 66px;
    height: 26px;
    padding: 0px 15px;
}

.medium {
    min-width: 184px;
    min-height: 36px;
    padding: 6px 8px;
}

.large {
    min-width: 300px;
    min-height: 48px;
    padding: 8px 16px;
}